<template>
    <li>
      <img class="goods-img" v-lazy="goodsItem.photoUrl"/>
      <h3>{{goodsItem.goodsName}}</h3>
      <div class="price-wrap">
        <div class="price-vip" v-if="goodsItem.vipPrice">会员价 ￥{{goodsItem.vipPrice}}/{{goodsItem.unit}}</div>
        <div class="price-normal">
          <span class="price-num">￥{{goodsItem.shopPrice}}</span>
          <span class="specs">/{{goodsItem.unit}}</span>
        </div>
      </div>
      <div class="car-icon">
        <img src="../images/cart-icon.png" @click.stop="addCarClick" />
        <span v-if="userId&&goodsItem.cartGoodsAmount>0">{{goodsItem.cartGoodsAmount}}</span>
      </div>
    </li>
</template>
<script>
export default {
  props: ['goodsItem', 'index', 'userId'],
  data () {
    return {
    }
  },
  methods: {
    addCarClick () {
      if (!this.userId) {
        this.$router.push('/login')
        return
      }
      this.$emit('addCar', this.goodsItem, this.index)
    }
  },
  created () {
    // this.userId = localStorage.userId
  }
}
</script>

<style lang="scss" scoped>
@import '../style/viriables.scss';
li{
  width: 47%;
  margin:.1rem 0 0 2%;
  background:#fff;
  border-radius: .05rem;
  position:relative;
  .goods-img{
    width: 100%;
    height:1.25rem;
    border-radius: .05rem .05rem 0 0;
  }
  h3{
    padding: 0 .1rem;
    font-size: .15rem;
    line-height: .25rem;
    overflow:hidden; text-overflow: ellipsis;display:-webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;
  }
  .price-wrap{
    padding: 0.05rem .1rem .1rem;
    .price-vip{
      display: inline-flex;
      height: .2rem;
      margin-bottom: .08rem;
      padding: 0 .1rem;
      background:$red-font;
      border-radius: .2rem;
      color:#fff;
      font-size: .13rem;
      align-items: center;
    }
    .price-normal{
      .price-num{color:$red-font;}
      .specs{color:#666}
    }
  }
  .car-icon{
    width:.26rem;
    height: .26rem;
    position:absolute;
    right:.1rem;
    bottom:.1rem;
    img{
      width:.26rem;
      height: .24rem;
    }
    span{
      min-width: .14rem;
      height: .14rem;
      background:$red-font;
      border-radius: .06rem;
      position: absolute;
      right: -.07rem;
      top: -.07rem;
      text-align: center;
      color:#fff;
      line-height: .14rem;
      font-size: .1rem
    }
  }
}
</style>
