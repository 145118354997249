<template>
    <SearchCom @cancelSearch="cancelSearch" @searchPlay="searchPlay" @searchListen="searchListen" :isShowBtn="true" :searchKeyInput="searchKey"/>
    <div class="search-cnt" v-if="!isShowResult">
        <div class="search-title-wrap">
            <h3>搜索历史</h3>
            <div class="search-title-r" @click="deleteHistory">
                <span>清空搜索历史</span>
                <span class="iconfont icon-shanchu"></span>
            </div>
        </div>
        <div class="keyword" v-if="historyList.length>0">
            <span @click="historySearchClick(item.remark)" v-for="item in historyList" :key="item.id">{{item.remark}}</span>
        </div>
        <div class="search-title-wrap">
            <h3>热门推荐</h3>
            <div class="search-title-r" @click="changeHot">
                <span>换一批</span>
                <span class="iconfont icon-shuaxin"></span>
            </div>
        </div>
        <div class="keyword" v-if="recommendList.length>0">
            <span @click="recomSearchClick(item)" v-for="item in recommendList" :key="item.id">{{item}}</span>
        </div>
    </div>
    <div class="search-result" v-if="isShowResult">
      <div class="search-list">
        <GoodsItem @click="goGoodsDetail(item)" @addCar="addCar" v-for="(item,index) in dataList" :key="item.id" :goodsItem="item" :userId="userId" :index="index"/>
      </div>
      <ContentNo :contentTips="contentTips" v-if="dataList.length==0"/>
    </div>
</template>
<script>
import qs from 'qs'
import { post, ajaxParam, config } from '../../units/request.js'
import SearchCom from '../../components/Search.vue'
import GoodsItem from '../../components/GoodsItem.vue'
import ContentNo from '../../components/ContentNo.vue'
export default {
  name: 'Search',
  components: { SearchCom, GoodsItem, ContentNo },
  data () {
    return {
      recommendList: [],
      historyList: [],
      dataList: [],
      pageNum: 1,
      pageSize: 6,
      searchKey: '',
      isShowResult: false,
      contentTips: '暂无内容'
    }
  },
  methods: {
    // 搜索历史和推荐关键词列表
    async getSearchHistory () {
      const url = 'wx/v1/shop/search.json'
      const param = {
        keywordsType: 1,
        userId: this.userId,
        pageNum: this.pageNum
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      this.recommendList = res.data.keywordsList
      this.historyList = res.data.list
    },
    // 点击推荐关键字搜索
    recomSearchClick (item) {
      this.searchKey = item
      this.getAllGoodsList()
    },
    // 点击搜索历史关键字搜索
    historySearchClick (item) {
      this.searchKey = item
      this.getAllGoodsList()
    },
    // 输入搜索
    searchListen (searchKeyInput) {
      this.searchKey = searchKeyInput
      // this.pageNum = 1
      // this.dataList = []
      // this.getAllGoodsList()
    },
    cancelSearch () {
      this.isShowResult = false
      this.searchKey = ''
      this.pageNum = 1
      this.dataList = []
      this.getSearchHistory()
    },
    searchPlay () {
      this.pageNum = 1
      this.dataList = []
      this.getAllGoodsList()
    },
    // 换一批
    changeHot () {
      this.pageNum++
      if (this.recommendList.length < 5) {
        this.pageNum = 1
      }
      this.getSearchHistory()
    },
    // 删除历史记录
    deleteHistory () {
      this.$dialog.confirm({
        title: '温馨提示',
        message: '是否删除历史记录'
      }).then(async () => {
        // confirm 删除
        const url = 'wx/v1/shop/dropSearch.json'
        const param = {
          userId: this.userId,
          type: 1
        }
        ajaxParam(param)
        const res = await post(url, qs.stringify(param))
        console.log(res)
        this.historyList = []
      }).catch(() => {
      })
    },
    // 获取全部商品
    async getAllGoodsList () {
      const url = 'v1/goods/user/cityShopGoods.json'
      const param = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        cityId: config.cityId,
        loadCatFlag: 0, // 是否加载分类
        goodsName: this.searchKey,
        userId: this.userId,
        addUserId: this.userId,
        recordFlag: 1 // 是否记录搜索历史
        // shopId: config.shopId
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      console.log(res)
      this.dataList.push(...res.data.list)
    },
    // 进商品详情
    goGoodsDetail (item) {
      this.$router.push({
        path: '/goodsdetail',
        query: {
          shopId: item.shopId,
          id: item.id
        }
      })
    },
    // 添加商品到购物车
    async addCar (model, index) {
      let cartGoodsAmount = 0
      var url = 'v1/cart/saveCart.json'
      var param = {
        shopId: model.shopId,
        userId: this.userId,
        goodsId: model.id,
        number: 1,
        cartId: ''
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      localStorage.carNumNew = res.data.cartNum
      res.data.list.forEach(item => {
        item.goodsList.forEach(itemSub => {
          if (itemSub.goodsId === model.id) {
            cartGoodsAmount = itemSub.goodsNum ? itemSub.goodsNum : item.cartGoodsAmount ? item.cartGoodsAmount : 0
          }
        })
      })
      this.dataList[index].cartGoodsAmount = cartGoodsAmount
      this.$toast(res.data.msg)
    }
  },
  created () {
    this.userId = localStorage.userId || ''
    this.getSearchHistory()
  },
  watch: {
    searchKey () {
      if (this.searchKey.length !== 0) {
        this.isShowResult = true
      } else {
        this.isShowResult = false
        this.dataList = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
body{background:#fff}
.search-wrap{display: flex; justify-content: space-between; align-items: center; background:#fff; padding: 0.1rem; align-items: center; color:#333;}
.search-bar{flex:1; display: flex; align-items: center; height: .32rem; background:#f3f3f3; margin-right: .1rem; padding: 0 .1rem; border-radius: .1rem; line-height: .32rem}
.search-bar img{width:.18rem; height: .18rem; margin-right: .1rem}
.search-bar input{flex:1; border:none; background:none}
.search-bar input:focus{outline:none}
.search-cnt{
  background:#fff;
  padding:0 .1rem;
  position:absolute;
  left: 0;
  right: 0;
  bottom:0;
  top: .5rem;
  .search-title-wrap {
    display:flex;
    justify-content: space-between;
    padding: .1rem 0 .12rem;
    .search-title-r{
      color:#777;
      font-size: .12rem;
    }
    .iconfont{
      margin-left: .05rem;
      font-size: .12rem;
      color:#777
    }
  }
  .keyword{
      display: flex;
      flex-wrap: wrap;
      span{
        display: inline-flex;
        background: #f0f2f5;
        padding: 0 .1rem;
        height: .2rem;
        border-radius: .04rem;
        margin-right: .15rem;
        margin-bottom: .15rem;
        line-height: .2rem;
      }
    }
}
.search-result{
  background:#fff;
  position:absolute;
  left: 0;
  right: 0;
  bottom:0;
  top: .5rem;
}
.search-list{
    display: flex;
    flex-wrap: wrap;
  }
</style>
