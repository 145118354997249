<template>
    <div class="content-no">
        <img src="../images/ico_notnull.png"/>
        <p>{{contentTips}}</p>
    </div>
</template>
<script>
export default {
  props: ['contentTips']
}
</script>
<style lang="scss" scoped>
.content-no{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: .5rem;
    color:#999;
    img {
        width: .4rem;
        height: .4rem;
        margin-bottom: .2rem;
    }
}
</style>
